import React from "react";
import { withTranslation } from "react-i18next";
import GoogleMaps from "../GoogleMaps";
import { tinyParallaxInit } from '../../dist/js/tiny-parallax-init'
import Modal from '../Modal'
import NetlifyForm from '../NetlifyForm'
import Mailto from "react-protected-mailto"

require ('./contact.scss')

class Contact extends React.Component {

    HIDE_TIMEOUT = 3000
    
    state = {
        name: '',
        email: '',
        phone: '',
        message: '',
        showSuccess: false,
        showWarning: false
    }

    getState = () => ({
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        message: this.state.message
    })
    
    handleChange = event => {
        const target = event.target
    
        this.setState({
          [target.name]: target.value,
        })
    }
    
    componentDidMount() {
        const $ = require('jquery')
        tinyParallaxInit()
        $('.contact-bg').tinyparallax();
    }

    validateForm() {
        return true
    }

    handleChange = event => {
        const target = event.target
    
        this.setState({
          [target.name]: target.value,
          showSuccess: false,
          showWarning: false
        })
    }

    onFormSuccess = message => {
        this.setState({showSuccess: true, name: "", email: "", phone: "", message: ""})
        setTimeout(() => this.setState({showSuccess: false}), this.HIDE_TIMEOUT)
    }

    onFormError = message => {
        this.setState({showError: true})
        console.log(message)
    }

    closeSuccess = () => {
        this.setState({showSuccess: false})
    }

    closeWarning = () => {
        this.setState({showWarning: false})
    }

    render() {
    
        const { t } = this.props

        return (
            <div className="contact_area contact-bg container-fluid dark-bg">
                <div className="row">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="section-title title textShadow">
                                    <span>The hotel <strong>unforgettable</strong></span>
                                    <h3>{t('can_we_help_you')}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="contact-items">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="contact-title">
                                                <i className="fa fa-map" aria-hidden="true"></i>
                                                <h3 className="contact">{t('tiliana_address')}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="fluid-wrapper google-maps">
                                    <GoogleMaps mapId="1dT6IaEnbSzDEvksLKbfAD1Q-pPv_wHjP" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="contact-items">
                                    <div className="row">
                                        <div className="col-lg-6 col-sm-12">
                                            <div className="contact-title">
                                                <i className="fa fa-phone" aria-hidden="true"></i>
                                                <Mailto className="contact" tel="+36 1 391 0027" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <div className="contact-title float-lg-right">
                                                <i className="fa fa-envelope"></i>
                                                <Mailto className="contact" email="sales@hoteltiliana.hu" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Modal show={this.state.showSuccess}
                                        type="success" 
                                        onClose={this.closeSuccess} 
                                        title={t('your_message_has_been_sent_successfully')} 
                                        message={t('our_colleagues_will_contact_you_as_soon_as_possible')} 
                                        />
                                <div className={"alert alert-warning alert-dismissible fade" + (this.state.showWarning ? " show" : " d-none")} role="alert">
                                    <p><strong>{t('warning')}</strong>{t(this.state.warningMessage)}</p>
                                    <button type="button" class="close" data-dismiss="alert" aria-label="Close" onClick={this.closeWarning}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <NetlifyForm subject="Tiliana kapcsolatfelvétel" className="contact-form" name="contact" onSuccess={this.onFormSuccess} onError={this.onFormError} state={this.getState()} action="/">
                                    <p className="d-none">
                                        <label>Don’t fill this out if you're human: <input type="text" name="age" value="18" /></label>
                                    </p>
                                    <div className="form-group">
                                        <input name="name" onChange={this.handleChange} placeholder={t('my_name')} type="text" value={this.state.name} required />
                                    </div>
                                    <div className="form-group">
                                        <input name="email" onChange={this.handleChange} type="email" placeholder={t('my_email')} value={this.state.email} required />
                                    </div>
                                    <div className="form-group">
                                        <input name="phone" onChange={this.handleChange} type="phone" placeholder={t('my_phone')} value={this.state.phone} required />
                                    </div>
                                    <div className="form-group">
                                        <textarea onChange={this.handleChange} className="form-control" name="message" value={this.state.message} placeholder={t('my_message')} required />
                                    </div>
                                    <button className="btn btn-fw btn-dark col-sm-12" type="submit">
                                        {t('send_message')}
                                    </button>
                                </NetlifyForm>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(Contact)