import React from "react";
import { Link } from "gatsby"
import 'owl.carousel/dist/assets/owl.carousel.css'
import { carouselInit } from './carousel-init'
import ModalVideo from 'react-modal-video'
import 'react-modal-video/scss/modal-video.scss'
import { withTranslation } from "react-i18next"
require ('./../../dist/scss/spinner.scss')
require ('./slider.scss')

class Slider extends React.Component {

    state = {
        isOpen: false,
        openedVideoId: null,
        loaded: false
    }

    constructor() {
        super()

        this.openModal = this.openModal.bind(this)
    }

    componentDidMount() {
        carouselInit()
        this.setState({loaded: true});
    }
    
    openModal(videoId) {
        this.setState({openedVideoId: videoId})
    }

    render() {

        const { slides } = this.props
        const { t } = this.props

        const isBrowser = typeof window !== "undefined"
        
        return (
            <div class="slider-area">
                <div className="sticky-spacer" />
                <div className={"spinner" + (this.state.loaded ? " hidden" : "")} />
                <div className="hero-slider owl-carousel" data-aos="flip-right">
                {slides.map((slide, index) => (
                    <div className={"single-slider" + ((index < 1) ? " slider-primary-overlay" : "")}>
                        <img src={slide.image.childImageSharp.fluid.src} srcset={slide.image.childImageSharp.fluid.srcSet} sizes={slide.image.childImageSharp.fluid.sizes} />
                            
                        <div className={"hero-slider-content " + slide.customClass + (slide.textShadow ? " textShadow" : "")}>
                            <h2 dangerouslySetInnerHTML={{ __html: slide.titleIntro }}></h2>
                            <h1 dangerouslySetInnerHTML={{ __html: slide.title }}></h1>
                            <p dangerouslySetInnerHTML={{ __html: slide.subTitle }}></p>

                        {slide.videoId && (
                            <button className="video-btn" onClick={() => this.openModal(slide.videoId)} />
                        )}
                        {slide.link && (
                            <Link className="link-btn" to={slide.link}>{slide.buttonLabel}</Link>
                        )}
                        </div>
                        {slide.videoId && (
                            <>
                                {isBrowser &&
                                    <ModalVideo channel={slide.videoChannel} isOpen={this.state.openedVideoId === slide.videoId} videoId={slide.videoId} onClose={() => this.setState({openedVideoId: null})} />
                                }
                            </>
                        )}
                    </div>
                ))}
                </div>
            </div>
        );
    }
}

export default withTranslation()(Slider)