import React from "react";
import Img from "gatsby-image"
import { roomSliderInit } from './roomslider-init'
import { withTranslation } from "react-i18next"
import { Link } from "gatsby"
import AOS from 'aos'
import scrollToComponent from 'react-scroll-to-component'
require ('./roomslider.scss')

class RoomSlider extends React.Component {

    scrollToRef = null

    componentDidMount() {
        roomSliderInit()
    }
    
    render() {

        const { t } = this.props
        const { rooms } = this.props
        const FADE_UP_DELAY = 200

        return (
            <>
            <a className="scroll-to-icon" onClick={() => scrollToComponent(this.scrollToRef, { offset: -10, align: 'top', duration: 800})}><i className="fa fa-chevron-circle-down fa-3x"></i></a>
            <div className="our-favorite-room-area pt-100" ref={(section) => { this.scrollToRef = section; }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title" data-aos="fade-up">
                                <span>The hotel <strong>unforgettable</strong></span>
                                <h3>{t('rooms')}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="collection__wrapper collection__activation owl__dot--cus owl-carousel owl-theme" data-aos="flip-up">    
                {rooms.map(({node: room}, index) => (
                    <div className="list__categories" data-aos="fade-up" data-aos-once="false" data-aos-delay={(index + 1) * FADE_UP_DELAY}>
                        <div className="thumb__catrgories">
                            <Link to={room.slug}>
                                <img sizes={room.frontmatter.image.childImageSharp.fluid.sizes} src={room.frontmatter.image.childImageSharp.fluid.src} srcSet={room.frontmatter.image.childImageSharp.fluid.srcSet} />
                            </Link>
                        </div>
                        <div className="desc__categories">
                            <div className="categories__content">
                                <h6><Link to={room.frontmatter.slug}>{room.frontmatter.title}</Link></h6>
                                <div dangerouslySetInnerHTML={{__html: room.excerpt}} />
                                <div className="p-amount">
                                    <span>{t('room_from')} </span>
                                    <span className="price">{room.frontmatter.price} </span>
                                    <span className="count">{t('per_person_per_night')}</span>
                                </div>
                                <div className="cat__btn">
                                    <Link className="shopbtn" to={room.frontmatter.slug}>{t('book_now')}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                </div>
            </div>
            </>
        );
    }
}

export default withTranslation()(RoomSlider)
