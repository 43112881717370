import React from "react";
import { withTranslation } from "react-i18next";
import { tinyParallaxInit } from '../../dist/js/tiny-parallax-init'
import './satisfaction.scss'

class Satisfaction extends React.Component {

    CAROUSEL_TIMEOUT = 10000

    state = {
        maxSlideIndex: 0,
        slideIndex: 0
    }

    testimonials = []

    componentDidMount() {
        const $ = require('jquery')
        tinyParallaxInit()
        $('.referral-area').tinyparallax()
        this.initCarousel()
        this.setState({maxSlideIndex: this.props.testimonials.length - 1})
    }

    initCarousel() {
        setInterval(() => {
            let slideIndex = (this.state.slideIndex < this.state.maxSlideIndex) ? this.state.slideIndex + 1 : 0
            this.setState({slideIndex: slideIndex})
        }, this.CAROUSEL_TIMEOUT)
    }

    render() {

        const { testimonials, satisfaction, t } = this.props

        return (
            <section className="referral-area referral-bg mb-95" style={{backgroundImage: "url(" + satisfaction.frontmatter.bgImage.childImageSharp.fluid.src + ")"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <div className="referral_rate mb-30 text-center">
                                <h4 data-aos="fade-right">{satisfaction.frontmatter.title}</h4>
                                <div dangerouslySetInnerHTML={{__html: satisfaction.html}} data-aos="flip-right" />
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <div className="referrals mb-30" data-aos="fade-left">
                                <div className="carousel slide" data-ride="carousel">
                                    <div className="carousel-inner" role="listbox">
                                        {testimonials.map(({node}, index) => (
                                            <div className={"carousel-item" + (this.state.slideIndex === index ? " active" : "")}>
                                                <div className="referral-content d-block w-100 col-lg-10 offset-lg-1">
                                                    <h3>
                                                        {node.frontmatter.name}
                                                    </h3>
                                                    <div dangerouslySetInnerHTML={{__html: node.html}} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )        
    }
}
export default withTranslation()(Satisfaction)