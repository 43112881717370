export function roomSliderInit() {

    const $ = require('jquery')
    window.jQuery = $;
    window.$ = $;

    require('owl.carousel')

    $(document).ready(function() {
        $('.collection__activation').owlCarousel({
            loop:true,
            margin:0,
            nav:true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            autoplay: false,
            autoplayTimeout: 10000,
            items:4,
            dots: true,
            responsive:{
                0:{
                  items:1
                },
                1920:{
                  items:3
                },
                992:{
                  items:3
                },
                768:{
                  items:2
                },
                576:{
                  items:2
                }
            }
        });
    })
}