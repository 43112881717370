

export function carouselInit() {

    const $ = require('jquery')
    window.jQuery = $;
    window.$ = $;

    require('owl.carousel')

    $(document).ready(function() {

        let height = $(window).height() - $('.default-header-area').height()
        $('.slider-area').height(height)
        $('.single-slider').height(height)

        let slider = $('.hero-slider')
        let touched = false

        slider.owlCarousel({
            smartSpeed: 1000,
            nav: true,
            dots: false,
            loop: true,
            lazyLoad: false,
            lazyLoadEager: 1,
            animateOut: '',
            animateIn: '',
            autoplay: true,
            navText: ['<i class="slider-pager fa fa-angle-left"></i>', '<i class="slider-pager fa fa-angle-right"></i>'],
            responsive: {
                0: {
                    items: 1,
                    nav: false
                },
                768: {
                    items: 1,
                    nav: false
                },
                991: {
                    items: 1,
                }
            }
        })

        $(document).on('click touchend', '.owl-prev, .owl-next, .video-btn', () => {
            slider.trigger('stop.owl.autoplay')
            let carousel = slider.data('owl.carousel')
            carousel.settings.autoplay = false
            carousel.options.autoplay = false
        })

        slider.on('drag.owl.carousel', (evt) => {
            slider.trigger('stop.owl.autoplay')
            let carousel = slider.data('owl.carousel')
            carousel.settings.autoplay = false
            carousel.options.autoplay = false
        })
    })
}