import React from "react";
import { Link } from "gatsby"
import { Img } from "gatsby-image"
import './instagram-bar.scss'

export default ({ data, className, animate }) => {
    const FADE_DELAY = 200
    
    return(

    <div className={className}>
    
    </div>)
}